#lyers {
    position: fixed;
    top: 10px;
    right: 10px;
    height: auto;

    z-index: 1002;



    .switcher {



        svg {
            width: 26px;
            height: 26px;
            color: #3e3e3e;
        }

        background-color: #ffffffd7;
        box-shadow: 0 0 5px #00000047;
        border-radius: 6px;
        padding: 5px;
        z-index: 10000000;
    }

    .layers {

        .header {
            display: flex;
        }

        .h {
            color: #222;
            background-color: #0000;
            border: none;
            outline: none;
            flex: 1;
            align-items: center;
            font-size: .8em;
            font-weight: bold;
            display: flex;
            padding: 5px;
        }

        .action {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #f6f6f6;
            cursor: pointer;
            margin-left: 5px;
            border: 0px;

            svg {
                color: #4d4d4d;
                width: 18px;
                height: 18px;
            }
        }

        h3 {
            font-size: 0.75em;
            font-weight: bold;
            margin-top: 10px;
            padding: 0 5px;
        }

        display: none;

        img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

        background-color: #fffffff1;
        box-shadow: 0 0 5px #00000047;
        border-radius: 6px;
        padding: 3px;
        z-index: 10000000;
        max-height: 80vh;
        overflow-y: auto;

        .layer-item {
            border: 2px solid transparent;
            cursor: pointer;
            padding: 2px 4px;
            position: relative;

            margin-bottom: 1px;

            display: flex;
            align-items: center;

            .layer-name {
                font-size: 0.7em;
                color: #3e3e3e;

            }

            svg {
                display: none;
            }

            &.selected {
                border-color: #008eeffc !important;


                svg {
                    position: absolute;
                    display: block;
                    right: 0px;
                    top: 0px;
                    color: #008eeffc;
                    width: 20px;
                    height: 20px;
                }
            }





        }


    }

    .layers2 {

        display: none;

        img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

        background-color: #fffffff1;
        box-shadow: 0 0 5px #00000047;
        border-radius: 6px;
        padding: 5px;
        z-index: 10000000;

        .layer-item {
            border: 2px solid transparent;
            cursor: pointer;
            padding: 5px;

            margin-bottom: 2px;

            display: flex;
            align-items: center;

            .layer-name {
                font-size: 0.7em;
                color: #3e3e3e;

            }

            &.selected {
                border-color: #008eeffc !important;
            }



        }


    }

}